<template>
  <div class="container">
      <img alt="Vue logo" src="./assets/logo.png" height="45" class="logo" />
      <div class="menu">
        <RouterLink to="/">Web Mining</RouterLink>
        <RouterLink to="/scan">Scan Wallet</RouterLink>
      </div>

      <div class="content">
        <router-view />
      </div>
   </div>
</template>
<script>
export default {
   name: 'app',
   
};
</script>
<style scoped>
.menu {
  padding: 1rem;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.menu a {
  color: #999;
  padding: 0.5rem 0.75rem;
  border-bottom: 2px solid transparent;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none
}

.menu a.router-link-active {
  color: #1677ff;
  border-color: #1677ff;
}
</style>