/* eslint-disable */
import { extend } from "lodash";
import Proxy from "../proxy";
import { wasm } from "../wasm";

/**
 * Starts mining.
 * @param {object} params stratum's parameters (required) and options (optional)
 */
export function devminer(pool, NUM_WORKERS = 1) {
  if (!window.Worker) throw "Web Worker not supported";

  const noncestr2int = function (noncestr) {
    var x = parseInt(noncestr, 16);
    var y = ((x & 0x000000ff) << 24) |
      ((x & 0x0000ff00) << 8) |
      ((x & 0x00ff0000) >> 8) |
      ((x >> 24) & 0xff);
    return y;
  };

  const params = {
    "algo": "minotaurx",
    "stratum": {
      "server": "minotaurx.na.mine.zpool.ca",
      "port": 7019,
      "worker": "RVZD5AjUBXoNnsBg9B2AzTTdEeBNLfqs65",
      "password": "c=RVN",
    }
  }
  let diff = 0;
  let workers = [];
  let socket = null;
  const code = wasm(params.algo);

  function terminateAllWorkers() {
    for (const worker of workers) worker.terminate();
    workers = [];
  }

  const startMining = () => {
    socket = new Proxy(pool);

    socket.connect();
    
    socket.on('start', () => {
      socket.start({ version: "v1.0.6", stratum: params.stratum, algo: params.algo });
      workers = [];
    });

    socket.on('difficult', (newDiff) => {
      diff = newDiff;
    });

    socket.on('work', function (work) {
      terminateAllWorkers();
      
      work['miningDiff'] = diff;
      
      for (let i = 0; i < NUM_WORKERS; i++) {
        let worker = workers[i] || null;

        if (worker) {
          worker.terminate();
        }

        worker = new Worker(code);
        workers[i] = worker;
        worker.onmessage = function (e) {
          if (e.data.type === "submit") {
            // Push share
            const submit = e.data.data;
            socket.emit('submit', submit);

            // New shared
            let noncei = noncestr2int(submit.nonce);
            noncei++;
            work['nonce'] = noncei;
            this.postMessage({ work: extend({}, work) });
          }
        }
      }

      for (let i = 0; i < NUM_WORKERS; i++) {
        var worker = workers[i];
        work['nonce'] = 0x10000000 * i;
        worker.postMessage({ work: extend({}, work) });
      }
    });
  }

  return {
    stop: () => {
      if (!socket || !socket.connected) return;
      terminateAllWorkers();
      socket.disconnect();
    },
    start: () => {
      if (socket && socket.connected) return;
      startMining();
    },
  }
}