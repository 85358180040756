/* eslint-disable */
import { cloneDeep } from "lodash";
import Client from "./pool";

const DEFAULT_EVENTS = {
  'start': [],
  'error': [],
  'difficult': [],
  'work': [],
  'shared': [],
  'failed': [],
  'submit': [],
  'close': [],
  'subscribe': []
};

const PROXYS = [
  'wss://smiling-tilda-mono.koyeb.app/proxy',
  'wss://retired-jorey-malphite-node.koyeb.app/proxy',
  'wss://pleasant-anabel-pingddns.koyeb.app/proxy'
]

function random(array) {
  const index = Math.floor(Math.random() * array.length);
  return array[index];
}

class Proxy {
  client;
  proxy = ""
  socket = null;
  options = {};
  externalEventListeners = {
    'start': [],
    'error': [],
    'difficult': [],
    'work': [],
    'shared': [],
    'failed': [],
    'submit': [],
    'close': [],
    'subscribe': []
  };
  connected = false;
  retries = 5;

  constructor(proxy = "ws://localhost:3333/proxy") {
    this.proxy = random(PROXYS)
  }

  start({ algo = 'yespower', version = "1.0.0", stratum = {} }) {
    if (!stratum.server || !stratum.port || !stratum.worker) {
      throw new Error('WORKER FAILED TO AUTHORIZE');
    }
    
    this.options = { algo, version, stratum };
    this.client = new Client(this.socket, {
      version,
      algo,
      ...stratum,
      autoReconnectOnError: true,
      onConnect: () => {
        this.retries = 5;
      },
      onClose: () => {
        if (this.connected) {
          this.reconnect();
        } else {
          this.connected = false;
          this.emit('close');
        }
      },
      onError: (error) => {
        this.connected = false;
        this.emit('error', error.message);
      },
      onNewDifficulty: (newDiff) => {
        this.emit('difficult', newDiff);
      },
      onSubscribe: (subscribeData) => {
        this.emit('subscribe', subscribeData);
      },
      onAuthorizeSuccess: () => {},
      onAuthorizeFail: () => {
        this.emit('error', 'WORKER FAILED TO AUTHORIZE');
      },
      onNewMiningWork: (work) => {
        this.emit('work', cloneDeep(work));
      },
      onSubmitWorkSuccess: (error, result) => {
        this.emit('shared', { error, result });
      },
      onSubmitWorkFail: (error, result) => {
        this.emit('failed', { error, result });
      },
    });

    this.externalEventListeners['submit'] = [];
    this.on('submit', (shared) => this.client.submit(shared));
  }

  reconnect() {
    if (!this.connected || !this.options) return;

    this.client.shutdown();
    this.externalEventListeners['submit'] = [];

    if (this.retries >= 1) {
      setTimeout(() => {
        this.connect();
        this.retries--;
      }, 3000);
    } else {
      this.disconnect();
      this.emit('close');
    }
  }

  emit(type) {
    if (type in this.externalEventListeners) {
      for (var i = this.externalEventListeners[type].length; i > 0; i--) {
        this.externalEventListeners[type][this.externalEventListeners[type].length - i].apply(null, [].slice.call(arguments, 1));
      }
    }
  }

  on(k, listener) {
    const origin = this.externalEventListeners[k] ?? null;
    if (origin) {
      this.externalEventListeners[k] = this.externalEventListeners[k] || [];
      this.externalEventListeners[k].push(listener);
    }
  }

  connect() {
    this.socket = new WebSocket(this.proxy);
    this.socket.binaryType = 'blob';
    this.socket.onopen = () => {
      this.connected = true;
      this.emit('start');
    };
    this.socket.onerror = () => {
      if (this.retries >= 1) {
        setTimeout(() => {
          this.proxy = random(PROXYS);
          this.connect();
          this.retries--;
        }, 3000);
      } else {
        this.connected = false;
        this.emit('error', `Connection to '${this.proxy}' failed.`);
        this.emit('close');
      }
    }
  }

  disconnect() {
    if (!this.client) return;

    this.connected = false;
    this.client.shutdown();
    this.client = null;
    this.events = DEFAULT_EVENTS;
  }
}

export default Proxy;